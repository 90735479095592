import Cookies from "universal-cookie"

export default class BaseTrackingService {

  constructor() {
    this.cookies = new Cookies();
    this.id = undefined;
    this.trackingConsentCookie = undefined;
    
  }

  setId(id) {
    this.id = id;
  }

  setTrackingConsentCookie(trackingConsentCookie) {
    this.trackingConsentCookie = trackingConsentCookie;
  }

  isSetTrackingConsentCookie() {
    if (!this.trackingConsentCookie) {
      return false;
    }

    return this.cookies.get(this.trackingConsentCookie) === `true`;
  }
}
