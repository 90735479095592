import BaseTrackingService from "./baseTrackingService";

class FacebookTrackingService extends BaseTrackingService {

  initialize() {
    if (this.trackingEnabled()) {
      window.fbq(`init`, this.id)
    }
  }

  visitPage() {
    this.invokePixel(`PageView`, {
      content_name: window.location.href
    });
  }

  contact() {
    this.invokePixel(`Contact`, {});
  }

  invokePixel(event, args) {
    if (this.trackingEnabled()) {
      window.fbq(`track`, event, args);
    }
  }

  trackingEnabled() {
    return typeof window !== 'undefined' && typeof window.fbq === `function` && this.isSetTrackingConsentCookie();
  }
}

let facebookTrackingService = new FacebookTrackingService();
export default facebookTrackingService;