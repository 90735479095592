import ReactGA from "react-ga";
import BaseTrackingService from "./baseTrackingService";

class GoogleAnalyticsTrackingService extends BaseTrackingService {

  initialize() {
    if (this.trackingEnabled()) {
      ReactGA.initialize(this.id);
    }
  }

  visitPage() {
    if (this.trackingEnabled()) {
        let page = window.location.href
        ReactGA.set({ page: page, anonymizeIp: true })
        ReactGA.pageview(page)
    }
  }

  contactViaForm() {
    this.sendEvent({
      category: 'Contact',
      action: 'Submitted form',
    });
  }

  contactViaEmail() {
    this.sendEvent({
      category: 'Contact',
      action: 'Clicked mailto link',
    });
  }

  bookOnlineWorkshop() {
    this.sendEvent({
      category: 'Booking',
      action: 'Book Online Workshop via modal',
    });
  }

  sendEvent(args) {
    if (this.trackingEnabled()) {
      ReactGA.event(args);
    }
  }

  trackingEnabled() {
    return this.isSetTrackingConsentCookie();
  }
}

let googleAnalyticsTrackingService = new GoogleAnalyticsTrackingService();
export default googleAnalyticsTrackingService;