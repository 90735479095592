import React from "react"
import Welcome from "../home/welcome"
import CookieBanner from "./cookieBanner"
import ExpandableMenu from "./expandableMenu"
import Footer from "./footer"
import Header from "./header"
import styles from "./layout.module.css"

export default class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuActive: false,
    }
    this.handleToggle = this.handleToggle.bind(this)
    this.children = props.children
    this.useWelcomeHeader = props.useWelcomeHeader
  }

  componentDidMount() {
    this.setState({ menuActive: false })
  }

  handleToggle(event) {
    this.setState({
      menuActive: !this.state.menuActive,
    })
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <ExpandableMenu
          menuActive={this.state.menuActive}
          handleToggle={this.handleToggle}
        />
        {this.useWelcomeHeader ? (
          <Welcome menuActive={this.state.menuActive} />
        ) : (
          <Header menuActive={this.state.menuActive} showTextLogo={true} />
        )}
        <div className={this.state.menuActive ? `contentBlur` : ``}>
          <main>{this.children}</main>
        </div>
        <CookieBanner />
        <Footer />
      </div>
    )
  }
}
