import { Link } from "gatsby"
import React, { useEffect } from "react"
import CookieConsent from "react-cookie-consent"
import facebookTrackingService from "../../services/facebookTrackingService"
import googleAnalyticsTrackingService from "../../services/googleAnalyticsTrackingService"
import styles from "./cookieBanner.module.css"
import config from "../../../data/SiteConfig"
import Cookies from "universal-cookie"

export default function CookieBanner() {

  const consentButtonId = `consent-button`;

  const initTracking = () => {
    googleAnalyticsTrackingService.setId(config.googleAnalyticsID);
    googleAnalyticsTrackingService.setTrackingConsentCookie(config.trackingConsentCookie)
    googleAnalyticsTrackingService.initialize();
    googleAnalyticsTrackingService.visitPage();
  
    facebookTrackingService.setId(config.facebookPixelID);
    facebookTrackingService.setTrackingConsentCookie(config.trackingConsentCookie);
    facebookTrackingService.initialize(config.facebookPixelID);
    facebookTrackingService.visitPage();
  }

  useEffect(
    () => {
      const cookies = new Cookies();
      if (cookies.get(config.trackingConsentCookie) === `true`) {
        initTracking();
      }
    }, []
  );
  
  return (
    <CookieConsent
      buttonText="Akzeptieren"
      declineButtonText="Ablehnen"

      enableDeclineButton={true}

      disableStyles={true}
      containerClasses={styles.wrapper}
      contentClasses={styles.content}
      buttonWrapperClasses={styles.buttonWrapper}
      buttonClasses={`info`}
      declineButtonClasses={`textLink`}
      buttonId={consentButtonId}
      onAccept={() => initTracking()}

      cookieName={config.trackingConsentCookie}
      sameSite={`lax`}
    >
      Diese Webseite verwendet Cookies. <Link to="/datenschutz/">Datenschutzerkl&auml;rung</Link>
    </CookieConsent>
  )
}
